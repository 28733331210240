import React, { useContext, useState,useEffect } from "react";
import Input from "../../components/input/Input";
import "./style/updatePost.css";
import { AppContext } from "../../context/modeContext";
import { useParams } from "react-router-dom";
import axios from 'axios'
import { apiUrl } from '../../constants'

const UpdatePost = () => {
  const [specificNews, setSpecificNews] = useState({})
  const [imageFile, setImageFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("token")
  const { mode } = useContext(AppContext);
  const {id} = useParams()

  const handleUpload = async (file) => {
    console.log("handle file upload");
    console.log("image file: ", file);

    // Create FormData and append the file
    const formData = new FormData();
    formData.append("file", file); // 'file' should match the key expected by the backend

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/upload/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("img response", response.data);
      setSpecificNews({
        ...specificNews,
        image: response.data.file.path
      }); // Update this based on your backend's response
    } catch (error) {
      console.error("error uploading image", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file); // Set the selected file
    handleUpload(file); // Upload the file immediately
  };

  const getSpecificPost = async () => {
    console.log("get specific post");
    const response = await axios.get(`${apiUrl}/api/v1/posts/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    console.log(response);
    if(response.data){
      setSpecificNews(response.data.post)
    }
  }
  
  useEffect(() => {
    getSpecificPost()
  },[])

  const updatePost = async () => {
    console.log("update post");
    setLoading(true)
    try {
      const response = await axios.put(`${apiUrl}/api/v1/posts/update-post/${id}`, specificNews, {
        headers : {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      console.log("update news resp", response);
      if(response.data){
        setLoading(false)
        alert("تم التحديث بنجاح")
      }else {
        setLoading(false)
        alert("هناك خطا حاول مرة اخري")
      }
    } catch (error) {
      setLoading(false)
      console.error("error call update", error)
      alert("هناك خطا حاول مرة اخري")
    }
  }
  return (
    <div className="createPost container">
    <h1 style={{ color: mode === "dark" ? "white" : "black" }}>تحديث خبر</h1>
    <div className="formPost">
      <div className="row">
        <div className="col-sm-6 input-label">
          <label style={{ color: mode === "dark" ? "white" : "black" }}>
            عنوان الخبر
          </label>
          <Input
            type={"text"}
            value={specificNews.title}
            onchange={(e) => {
              setSpecificNews({
                ...specificNews,
                title: e.target.value
              })
            }}
            placeholder={"حدث معين"}
            bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
          />
        </div>

        <div className="col-sm-6 input-label">
            <label
              style={{ color: mode === "dark" ? "white" : "black" }}
              className="d-block "
            >
              القسم
            </label>
            <select
              className="selectt"
              name="category"
              id="category"
              value={specificNews.category}
              onChange={(e) => {
                setSpecificNews({
                  ...specificNews,
                  category: e.target.value
                })
              }}
              style={{
                backgroundColor: "rgba(33, 99, 232, 0.2)",
                color: mode === "dark" ? "white" : "black",
              }}
            >
              <option value={"local"}>محلية</option>
              <option value={"international"}>عالمية</option>
              <option value={"national"}>دولية</option>
              <option value={"sports"}>رياضية</option>
              <option value={"culture"}>ثقافية</option>
              <option value={"inspired"}>شخصيات ملهمة</option>
            </select>
          </div>
      </div>

      <div className="row">
        <div className="col-sm-6 input-label">
          <label style={{ color: mode === "dark" ? "white" : "black" }}>
            ملخص عن الخبر
          </label>
          <div className="area">
            <textarea
              value={specificNews.summary}
              onChange={(e) => {
                setSpecificNews({
                  ...specificNews,
                  summary: e.target.value
                })
              }}
              placeholder="ملخص عن الخبر ...."
              style={{
                backgroundColor: "rgba(33, 99, 232, 0.2)",
                color: mode === "dark" ? "white" : "black",
              }}
            />
          </div>
        </div>

        <div className="col-sm-6 input-label">
          <label style={{ color: mode === "dark" ? "white" : "black" }}>
            تفاصيل عن الخبر
          </label>
          <div className="area">
            <textarea
              value={specificNews.body}
              onChange={(e) => {
                setSpecificNews({
                  ...specificNews,
                  body: e.target.value
                })
              }}
              placeholder="تفاصيل الخبر ......."
              style={{
                backgroundColor: "rgba(33, 99, 232, 0.2)",
                color: mode === "dark" ? "white" : "black",
              }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 input-label">
          <label style={{ color: mode === "dark" ? "white" : "black" }}>
            صورة عن الخبر
          </label>
          <Input
            type={"file"}
            onchange={handleFileChange}
            placeholder={"صورة تعبر عن الخبر"}
            bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
          />
        </div>
      </div>
    </div>

    <div className="createBtn">
      <button onClick={updatePost}>{loading ? "جاري التحديث" : "تحديث"}</button>
    </div>
  </div>
  )
}

export default UpdatePost
