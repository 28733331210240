import React, { useContext} from 'react'
import { MdLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import './style/mode.css'
import { AppContext } from './../../context/modeContext';

const Mode = () => {
  const {mode, setMode} = useContext(AppContext)
  return (
    <div className='mode'>
      {mode === "light" ? <MdDarkMode size={30} onClick={() => setMode("dark")} /> : <MdLightMode color='white' size={30} onClick={() => setMode("light")} />}
    </div>
  )
}

export default Mode
