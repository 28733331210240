import React, { useContext } from 'react'
import './style/card.css'
import { AppContext } from '../../context/modeContext'
import { useNavigate } from 'react-router-dom'


const NewsCard = ({title, description,image, id}) => {
    const navigate = useNavigate()
    const {mode} = useContext(AppContext)

    
    
  return (
    <div className='newsCard'>
      <div className="newsImg">
        <img src={image} alt='صورة الخبر'/>
      </div>
      <div className="newsDetails">
        <h2 style={{color: mode === "dark" ? "white" : "black"}} onClick={() => navigate(`/specific-news/${id}`)}>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default NewsCard
