import React, { useContext } from 'react'
import './style/input.css'
import { AppContext } from '../../context/modeContext'

const Input = ({value,placeholder,onchange,bgcolor,type}) => {
  const {mode} = useContext(AppContext)
  return (
    <div className='inputContainer'>
      <input type={type} value={value} placeholder={placeholder} onChange={onchange} className='input' style={{backgroundColor: "rgba(33, 99, 232, 0.2)", color: mode === "dark" ? "white" : "black"}} />
    </div>
  )
}

export default Input
