import React, { useContext } from "react";
import Table from "react-bootstrap/Table";
import "./style/table.css";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { AppContext } from "../../context/modeContext";
import axios from "axios";
import { apiUrl } from "./../../constants/index";
import { useNavigate } from "react-router-dom";

const AdminTable = ({ columns, values, onClickDelete, onClickUpdate }) => {
  const navigate = useNavigate()
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");

  // delete post
  const deletePost = async (id) => {

    console.log("delete post function");
    try {
      const response = await axios.delete(
        `${apiUrl}/api/v1/posts/delete-post/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("delete response", response);
      if (response.data) {
        alert("تم مسح هذا البوست");
        window.location.reload();
      }
    } catch (error) {
      console.error("error call deleting", error);
    }
  };
  return (
    <div className="adminTable">
      <Table
        striped
        bordered
        hover
        variant={mode === "dark" ? "dark" : "light"}
      >
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th id="th" key={index}>
                <h6>{col}</h6>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {values.map((val) => {
            return (
              <tr key={val._id}>
                <td>{val.title}</td>
                <td>{val.summary}</td>
                <td>{val.body}</td>
                <td>{val.category}</td>
                <td>
                  <img className="tableImg" src={val.image} alt="صورة البوست" />
                </td>
                <td>
                  <div className="icons">
                    <MdDelete
                      size={25}
                      className="delIcon"
                      onClick={() => {
                        deletePost(val._id);
                        onClickDelete();
                      }}
                    />
                    <FaEdit
                      size={25}
                      className="updIcon"
                      onClick={() => {
                        onClickUpdate()
                        navigate(`/update-post/${val._id}`)
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminTable;
