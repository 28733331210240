import React, { useContext } from 'react'
import Mode from '../mode/Mode';
import Resizer from '../resizer/Resizer';
import Translation from '../translation/Translation';
import './style/bar.css'
import { AppContext } from '../../context/modeContext';

const TopBar = () => {
  const {mode} = useContext(AppContext)
  return (
    <div className={mode === "light" ? 'tobBar' : "tobBarDark"}>
      <Resizer/>
      <Mode/>
      <Translation/>
    </div>
  )
}

export default TopBar
