import React, { useContext } from "react";
import './style/member.css'
import { AppContext } from "../../context/modeContext";

const TeamMember = ({name,position, image}) => {
    const {mode} = useContext(AppContext)
  return (
    <div className="member">
      <div className="personImg">
        <img src={image} alt="صورة شخص" />
      </div>
      <div className="persDetails">
        <h5 style={{color: mode === "dark" ? "white" : "black"}}>{name}</h5>
        <h6>{position}</h6>
      </div>
    </div>
  );
};

export default TeamMember;
