import React, { useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import './style/resizer.css';

const Resizer = () => {
  const [radioValue, setRadioValue] = useState("2");
  
  const radios = [
    { name: "كبير", value: "1" },
    { name: "وسط", value: "2" },
    { name: "صغير", value: "3" },
  ];

  const resize = (fontPx) => {
    if (fontPx === "1") {
      document.querySelector("html").style.fontSize = "18px";
    } else if (fontPx === "2") {
      document.querySelector("html").style.fontSize = "16px";
    } else if (fontPx === "3") {
      document.querySelector("html").style.fontSize = "14px";
    } else {
      console.log("default font");
    }
  };

  return (
    <div className="resizer">
      <ButtonGroup>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant="outline-primary"
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => {
              setRadioValue(e.currentTarget.value);
              resize(e.currentTarget.value);
            }}
            className={radioValue === radio.value ? "selected-button" : ""}
            style={{borderRadius: "0"}}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default Resizer;
