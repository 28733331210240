import React, { useContext, useEffect, useState } from "react";
import "./style/talks.css";
import { FaQuoteRight } from "react-icons/fa6";
import { FaQuoteLeft } from "react-icons/fa6";
import { AppContext } from "../../context/modeContext";
import LatestPosts from "../../components/latestPosts/LatestPosts";
import axios from "axios";
import { apiUrl } from "../../constants";

const Talks = () => {
  const { mode } = useContext(AppContext);
  const [talks, setTalks] = useState([]);

  const getTalks = async () => {
    console.log("get talks");
    try {
      const response = await axios.get(`${apiUrl}/api/v1/pioneerWords`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("talks response:", response);
      if (response.data) {
        setTalks(response.data.data.words);
      }
    } catch (error) {
      console.error("error caling api", error);
    }
  };

  useEffect(() => {
    getTalks();
  }, []);

  return (
    <section className="pioneerTalks container">
      <div className="talk row">
        {talks.map((word, index) => (
          <div
            className="col-sm-12 my-5 mx-auto talkCard"
            key={index}
            style={{
              backgroundColor: "rgba(33, 99, 232, 0.2)",
              color: mode === "dark" ? "white" : "black",
            }}
          >
            <div className="imgAndTalk">
              <div className="talkImg">
                <img src={word.image} alt={word.body} />
              </div>
              <div className="talkWords">
                <p><FaQuoteRight className="mx-3" color={mode === "dark" ? "white" : "black"}/>{word.body}<FaQuoteLeft className="mx-3" color={mode === "dark" ? "white" : "black"}/></p>
                <div className="talkName">
                  <h4
                    style={{
                      color: mode === "dark" ? "#a1a1a8" : "#03346E",
                    }}
                  >
                    -- {word.name} --
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="latestPosts my-5">
        <LatestPosts />
      </div>
    </section>
  );
};

export default Talks;
