import React from "react";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
// import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./style/nav.css";
import logo from "../../imgs/logo.svg";
import TopBar from "../tobBar/TopBar";
import { useContext } from "react";
import { AppContext } from "../../context/modeContext";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { mode } = useContext(AppContext);
  return (
    <div className="container">
      <div className="top-bar">
        <TopBar />
      </div>
      <div className="navbarr">
        <Navbar expand="lg" className="nav-bar">
          <Container fluid>
            <Navbar.Brand>
              <img src={logo} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              style={{ backgroundColor: "#2163e8" }}
            />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="m-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link className={mode === "light" ? "link" : "linkDark"}>
                  <Link to={"/"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >الرئيسية</Link>
                </Nav.Link>
                <NavDropdown
                  title="أخبار"
                  id={mode === "dark" ? "navbarScrollingDropdownDark" : "navbarScrollingDropdown"}
                  className={mode === "light" ? "link" : "linkDark"}
                >
                  <NavDropdown.Item
                    className={mode === "light" ? "link" : "linkDark"}
                  >
                    <Link to={"/news/local"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >أخبار محلية</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={mode === "light" ? "link" : "linkDark"}
                  >
                    <Link to={"/news/international"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >أخبار عالمية</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={mode === "light" ? "link" : "linkDark"}
                  >
                    <Link to={"/news/arab"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >أخبار دولية</Link>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="أخبار عامة"
                  id={mode === "dark" ? "navbarScrollingDropdownDark" : "navbarScrollingDropdown"}
                  className={mode === "light" ? "link" : "linkDark"}
                >
                  <NavDropdown.Item
                    className={mode === "light" ? "link" : "linkDark"}
                  >
                    <Link to={"/news/sports"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >أخبار رياضية</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={mode === "light" ? "link" : "linkDark"}
                  >
                    <Link to={"/news/Cultural"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >أخبار ثقافية</Link>
                  </NavDropdown.Item> 
                </NavDropdown>

                <NavDropdown
                  title="شخصيات"
                  id={mode === "dark" ? "navbarScrollingDropdownDark" : "navbarScrollingDropdown"}
                  className={mode === "light" ? "link" : "linkDark"}
                >
                  <NavDropdown.Item
                    className={mode === "light" ? "link" : "linkDark"}
                  >
                    <Link to={"/talks"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >كلمات رواد التنمية</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={mode === "light" ? "link" : "linkDark"}
                  >
                    <Link to={"/news/inspiringPersonalities"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >شخصيات ملهمة</Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  className={mode === "light" ? "link" : "linkDark"}
                >
                  <Link to={"/disabled-people-ads"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >اعلانات ذوي العلاقة</Link>
                </Nav.Link>
                <Nav.Link
                  href="#action1"
                  className={mode === "light" ? "link" : "linkDark"}
                >
                  <Link to={"/news/organization"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >أخبار منظمة ذوي الاعاقة</Link>
                </Nav.Link>
                <Nav.Link
                  className={mode === "light" ? "link" : "linkDark"}
                >
                  <Link to={"/contact-us"} className={mode === "light" ? "innerLink" : "innerLinkDark"} >تواصل</Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBar;
