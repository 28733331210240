import React, { useContext, useEffect, useState } from "react";
import Input from "../../components/input/Input";
import "./style/updateEmp.css";
import { AppContext } from "../../context/modeContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants"; 

const UpdateEmp = () => {
    const [user, setUser] = useState({})
    const [imageFile, setImageFile] = useState(null)
    const {id} = useParams()
    const { mode } = useContext(AppContext);
    const [loading, setLoading] = useState()
    const token = localStorage.getItem("token")

    const handleUpload = async (file) => {
      console.log("handle file upload");
      console.log("image file: ", file);
  
      // Create FormData and append the file
      const formData = new FormData();
      formData.append("file", file); // 'file' should match the key expected by the backend
  
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/upload/uploadImage`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Important for file upload
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("img response", response.data);
        setUser({
          ...user,
          image: response.data.file.path}); // Update this based on your backend's response
      } catch (error) {
        console.error("error uploading image", error);
      }
    };
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setImageFile(file); // Set the selected file
      handleUpload(file); // Upload the file immediately
    };

    const getUser = async () => {
      console.log("get special user");
      setLoading(true)

      try {
        const response = await axios.get(`${apiUrl}/api/v1/users/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        console.log("user: ",response);
        if(response.data){
          setLoading(false)
          setUser(response.data.user)
        }else{
          setLoading(false)
          alert("هناك خطا حاول وقت لاحق")
        }
      } catch (error) {
        setLoading(false)
        alert("هناك خطا حاول وقت لاحق")
        console.error("error fetch special user")
      }
    }
  
    useEffect(()=>{
      getUser()
    },[id])
    
    const updateUser = async () => {
      console.log("update user");
      setLoading(true)
      try {
        const response = await axios.put(`${apiUrl}/api/v1/users/update/${id}`,user, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        if(response.data){
          setLoading(false)
          alert("تم التحديث بنجاح")
        }else{
          setLoading(false)
          alert("هناك خطا حاول في وقت لاحق")
        }
      } catch (error) {
        setLoading(false)
        alert("هناك خطا حاول في وقت لاحق")
        console.error("error fetch updating user", user)
      }
      
    }
    return (
      <div className="createPost container">
      <h1 style={{ color: mode === "dark" ? "white" : "black" }}>تحديث موظف</h1>
      <div className="formPost">
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              اسم الموظف
            </label>
            <Input
              type={"text"}
              value={user.name}
              onchange={(e) => {
                setUser({
                  ...user,
                  name: e.target.value
                })
              }}
              placeholder={" مثال: احمد محمد"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label
              style={{ color: mode === "dark" ? "white" : "black" }}
            >{`اسم الموظف(لتسجيل الدخول)`}</label>
            <Input
              type={"text"}
              value={user.username}
              onchange={(e) => {
                setUser({
                  ...user,
                  username: e.target.value
                })
              }}
              placeholder={"اسم الموظف"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الوظيفة
            </label>
            <Input
              type={"text"}
              value={user.position}
              onchange={(e) => {
                setUser({
                  ...user,
                  position: e.target.value
                })
              }}
              placeholder={"عضو - مدير - مسئول"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              العنوان الوظيفي
            </label>
            <Input
              type={"text"}
              value={user.role}
              onchange={(e) => {
                setUser({
                  ...user,
                  role: e.target.value
                })
              }}
              placeholder={"مؤلف"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          
        </div>

        <div className="row">
          {/* <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              كلمة المرور
            </label>
            <Input
              type={"password"}
              value={user.password}
              onchange={(e) => {
                setUser({
                  ...user,
                  password: e.target.value
                })
              }}
              placeholder={"كلمة المرور"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div> */}

          <div className="col-sm-6 input-label">
            <label
              style={{ color: mode === "dark" ? "white" : "black" }}
              className="d-block "
            >
              يعرض في فريق العمل ؟
            </label>
            <select
              className="selectt"
              name="category"
              id="category"
              value={user.inTeamWork}
              onChange={(e) => {
                setUser({
                  ...user,
                  inTeamWork: e.target.value
                })
              }}
              style={{
                backgroundColor: "rgba(33, 99, 232, 0.2)",
                color: mode === "dark" ? "white" : "black",
              }}
            >
              <option value={true}>نعم</option>
              <option value={false}>لا</option>
            </select>
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              البريد الالكتروني
            </label>
            <Input
              type={"email"}
              value={user.email}
              onchange={(e) => {
                setUser({
                  ...user,
                  email: e.target.value
                })
              }}
              placeholder={"البريد الالكتروني"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الصورة
            </label>
            <Input
              type={"file"}
              onchange={handleFileChange}
              placeholder={" صورة الموظف"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>
      </div>

      <div className="createBtn">
        <button onClick={updateUser}>{loading ? "جاري التحديث" : "تحديث"}</button>
      </div>
    </div>
    );
}

export default UpdateEmp
