import React, { useContext, useEffect, useState } from "react";
import Slider from "../../components/slider/Slider";
import "./style/home.css";
import TeamMember from "../../components/teamMember/TeamMember";
import LatestPosts from "../../components/latestPosts/LatestPosts";
import TrendingNews from "../../components/trendingNews/TrendingNews";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaMicrophone } from "react-icons/fa6";
import { AppContext } from "../../context/modeContext";
import { RiTeamFill } from "react-icons/ri";
import { FaQuoteRight } from "react-icons/fa6";
import { FaQuoteLeft } from "react-icons/fa6";
import whoWeAre from "../../imgs/who-we-are.png";
import axios from "axios";
import { apiUrl } from "../../constants";
import OneAd from "../../components/oneAd/OneAd";
import owner from '../../imgs/owner.jpg'
const Home = () => {
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployees = async () => {
    setLoading(true);
    console.log("get employees");
    try {
      const response = await axios.get(`${apiUrl}/api/v1/users/TeamWork`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response: ", response);
      if (response.data) {
        setLoading(false);
        console.log(response);
        setEmployees(response.data.TeamWork.TeamWork);
      } else {
        console.log("error in response");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("error calling employees api");
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <div className="container">
      <div className="trending">
        <TrendingNews />
      </div>

      <main>
        <Slider />
      </main>

      <section className="brief">
        <div className="titleAndDesc">
          <h1 style={{ color: mode === "dark" ? "white" : "black" }}>
            <FaPeopleGroup color="#2163e8" /> من نحن ؟
          </h1>
          <p>
            موقع إخباري ثقافي اجتماعي رياضي يعمل على إبراز الصورة الإيجابية
            للأشخاص ذوي الإعاقة، ويسلط الضوء على أهم الإنجازات والنجاحات
            والشخصيات الرائدة بغرض تحسين نظرة المجتمع تجاه هذه الفئة الملهمة.
          </p>
        </div>
        <div className="whoImg">
          <img src={whoWeAre} alt="من نحن" />
        </div>
      </section>

      <section className="brief">
        <div className="whoImg">
          <img
            src={owner}
            alt="كلمة رئيس مجلس الادارة"
          />
        </div>
        <div className="titleAndDesc">
          <h3 style={{ color: mode === "dark" ? "white" : "black" }}>
            <FaMicrophone color="#2163e8" size={35} /> كلمة رئيس مجلس الادارة{" "}
          </h3>
          <p>
            <FaQuoteRight color="#2163e8" className="m-2" size={30} />
            نحن منظمة تدعم ذوي الهمم وذوي القدرات الخاصة وتوفر لهم احدث الاخبار
            الرياضية والمحلية والرياضية والثقافية والعالمية والدولية
            <FaQuoteLeft color="#2163e8" className="m-2" size={30} />
          </p>
        </div>
      </section>

      <section className="team container-fluid">
        <h3 style={{ color: mode === "dark" ? "white" : "black" }}>
          {" "}
          <RiTeamFill color="#2163e8" /> فريق العمل{" "}
        </h3>
        {loading ? (
          <h4 style={{ color: mode === "dark" ? "white" : "black" }}>
            جار التحميل
          </h4>
        ) : (
          <div className="members row">
            {employees.map((emp, index) => (
              <div className="col-sm-3 mx-auto my-3" key={index}>
                <TeamMember
                  name={emp.name}
                  position={emp.position}
                  image={emp.image}
                />
              </div>
            ))}
          </div>
        )}
      </section>

      <section className="latestSection">
        <LatestPosts />
      </section>

      <OneAd />
    </div>
  );
};

export default Home;
