import React, { useContext } from "react";
import "./style/search.css";
import { AppContext } from "../../context/modeContext";

const Search = ({  value, onchange, clickFunction, allPosts }) => {
  const { mode } = useContext(AppContext);
  return (
    <div className="my-3 mx-auto w-75 d-flex flex-wrap justify-content-center align-items-center g-3">
      <select
        className="selectt"
        name="category"
        id="category"
        value={value}
        onChange={onchange}
        style={{
          backgroundColor: "rgba(33, 99, 232, 0.2)",
          color: mode === "dark" ? "white" : "black",
        }}
      >
        <option value={""}>القسم</option>
        <option value={"local"}>محلية</option>
        <option value={"international"}>عالمية</option>
        <option value={"arab"}>دولية</option>
        <option value={"sports"}>رياضية</option>
        <option value={"Cultural"}>ثقافية</option>
        <option value={"inspiringPersonalities"}>شخصيات ملهمة</option>
        <option value={"organization"}>منظمة ذوي الاعاقة</option>
      </select>
      <button className="searchButton mx-2" onClick={clickFunction} style={{color: mode === "dark" ? "white" : "black"}}>بحث</button>
      <button className="searchButton mx-2" onClick={allPosts} style={{color: mode === "dark" ? "white" : "black"}}>الكل</button>
    </div>
  );
};

export default Search;
