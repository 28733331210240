import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style/empView.css";
import { AppContext } from "../../context/modeContext";
import EmpTable from "../../components/empTable/EmpTable";
import axios from "axios";
import { apiUrl } from "../../constants";

const EmployeesView = () => {
  const navigate = useNavigate();
  const { mode } = useContext(AppContext);
  const [employees, setEmployees] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false)

  //get employees
  const getEmployees = async () => {
    setLoading(true)
    console.log("get employees");
    try {
      const response = await axios.get(`${apiUrl}/api/v1/users`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      console.log("response: ", response);
      if(response.data){
        setLoading(false)
        setEmployees(response.data.data.users) 
      }else{
        console.log("error in response");
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("error calling employees api")
    }
  }

  useEffect(()=>{
    getEmployees()
  },[])

  
  return (
    <div className="container postsView">
      <div className="createButtonContainer">
        <Link
          to={"/create-author"}
          className="createButton"
          style={{ color: mode === "dark" ? "white" : "black" }}
        >
          اضافة موظف
        </Link>
      </div>
      {loading ? (
        <div className="loading my-5 mx-auto text-center">
          <h3>جار التحميل</h3>
        </div>
      ) : null}
      <div className="table">
        <EmpTable
          columns={["اسم الموظف","اسم تسجيل الدخول","البريد الاكتروني", "موظف/مدير","الوظيفة","الصورة", "الاجراءات"]}
          values={employees}
          onClickUpdate={() => console.log("update emp")}
          onClickDelete={() => console.log("deleted")}
        />
      </div>
    </div>
  );
};

export default EmployeesView;
