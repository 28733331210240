import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style/postsView.css";
import { AppContext } from "../../context/modeContext";
import AdminTable from "../../components/adminTable/AdminTable";
import axios from "axios";
import { apiUrl } from "./../../constants/index";
import Search from "./../../components/search/Search";

const PostsView = () => {
  const { mode } = useContext(AppContext);
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role")

  // get posts
  const getPosts = async () => {
    console.log("get posts function");
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/posts/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response: ", response);

      if (response.data) {
        setLoading(false);
        setPosts(response.data.data.posts || []);
      } else {
        setLoading(false);
        console.log("error in response");
      }
    } catch (error) {
      setLoading(false);
      console.error("error calling api", error);
    }
  };

  const getAuthorPosts = async () => {
    console.log("get author posts function");
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/posts/getAuthorPosts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response: ", response);

      if (response.data) {
        setLoading(false);
        setPosts(response.data.posts || []);
      } else {
        setLoading(false);
        console.log("error in response");
      }
    } catch (error) {
      setLoading(false);
      console.error("error calling api", error);
    }
  };

  useEffect(() => {
    role === "admin" && getPosts()
    role === "author" && getAuthorPosts()
  }, []);

  const clickFunction = async () => {
    console.log("get posts function");
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/posts/?category=${category}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response: ", response);

      if (response.data) {
        setLoading(false);
        setPosts(response.data.data.posts);
      } else {
        setLoading(false);
        console.log("error in response");
      }
    } catch (error) {
      setLoading(false);
      console.error("error calling api", error);
    }
  };

  return (
    <div className="container postsView">
      <div className="search">
        <Search
          value={category}
          onchange={(e) => setCategory(e.target.value)}
          clickFunction={clickFunction}
          allPosts={getPosts}
        />
      </div>
      <div className="createButtonContainer">
        <Link
          to={"/create-post"}
          className="createButton"
          style={{ color: mode === "dark" ? "white" : "black" }}
        >
          اضافة خبر
        </Link>
      </div>
      {loading ? (
        <div className="loading my-5 mx-auto text-center">
          <h3>جار التحميل</h3>
        </div>
      ) : null}
      <div className="table">
        <AdminTable
          columns={[
            "العنوان",
            "الملخص",
            "التفاصيل",
            "القسم",
            "الصورة",
            "الاجراءات",
          ]}
          values={posts}
          onClickUpdate={() => console.log("update func")}
          onClickDelete={() => console.log("deleted")}
        />
      </div>
    </div>
  );
};

export default PostsView;
