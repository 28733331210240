import React, { useContext, useState } from "react";
import Input from "../../components/input/Input";
import { AppContext } from "../../context/modeContext";
import "./style/createAds.css";
import axios from "axios";
import { apiUrl } from "../../constants";
import { useNavigate } from "react-router-dom";

const CreateAds = () => {
  const [companyName, setCompanyName] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const [error, setError] = useState("");
  const navigate = useNavigate()

  const handleUpload = async (file) => {
    console.log("handle file upload");
    console.log("image file: ", file);

    // Create FormData and append the file
    const formData = new FormData();
    formData.append("file", file); // 'file' should match the key expected by the backend

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/upload/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("img response", response.data);
      setImage(response.data.file.path); // Update this based on your backend's response
    } catch (error) {
      console.error("error uploading image", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file); // Set the selected file
    handleUpload(file); // Upload the file immediately
  };

  const addAds = async () => {
    console.log("add Ads");
    setLoading(true);
    if (companyName.length === 0) {
      setError("يجب ادخال اسم الشركة");
      setLoading(false)
    } else if (image.length === 0) {
      setError("يجب ادخال صورة");
      setLoading(false)
    } else {
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/companyAds/create-companyAd`,
          {
            companyName,
            image,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("create ads response", response);
        if (response.data) {
          setLoading(false);
          setError("")
          alert("تمت الاضافة بنجاح");
          navigate("/ads-view")
        } else {
          setLoading(false);
          setError("")
          alert("هناك خطا حاول مرة اخري");
        }
      } catch (error) {
        setLoading(false);
        setError("")
        console.error("error fetch add ads", error);
        alert("هناك خطا حاول مرة اخري في وقت اخر");
      }
    }
  };

  return (
    <div className="createPost container">
      <h1 style={{ color: mode === "dark" ? "white" : "black" }}>
        انشاء اعلان
      </h1>
      {error.length > 0 && <h4 className="text-danger text-center my-3 mx-auto">{error}</h4>}
      <div className="formPost">
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              اسم الشركة
            </label>
            <Input
              type={"text"}
              value={companyName}
              onchange={(e) => setCompanyName(e.target.value)}
              placeholder={"اسم الشركة"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              صورة الاعلان
            </label>
            <Input
              type={"file"}
              onchange={handleFileChange}
              placeholder={"صورة الاعلان"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>
      </div>

      <div className="createBtn">
        <button onClick={addAds}>{loading ? "جاري الاضافة" : "اضافة"}</button>
      </div>
    </div>
  );
};

export default CreateAds;
