import React, { useCallback, useContext, useEffect, useState } from "react";
import { Carousel, Card, Row, Col } from "react-bootstrap";
import { AppContext } from "../../context/modeContext";
import "./style/posts.css";
import { MdNewspaper } from "react-icons/md";
import axios from "axios";
import { apiUrl } from "../../constants";
import { useNavigate } from "react-router-dom";

const LatestPosts = () => {
  const { mode } = useContext(AppContext);
  const [latestPosts, setLatestPosts] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const getLatest = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/posts?limit=6`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("latest posts: ", response.data.data.posts);
      if (response.data) {
        setLatestPosts(response.data.data.posts);
      }
    } catch (error) {
      console.error("Error getting latest posts", error);
    }
  }, []);

  useEffect(() => {
    getLatest();
  }, [getLatest]);

  // Split posts into groups of three
  const groupedPosts = [];
  for (let i = 0; i < latestPosts.length; i += 3) {
    groupedPosts.push(latestPosts.slice(i, i + 3));
  }

  return (
    <div className="latest">
      <div className="latestNewsTitle">
        <MdNewspaper color="#2163e8" size={40} />
        <h4 style={{ color: mode === "dark" ? "white" : "black" }}>
          أخر الاخبار
        </h4>
      </div>
      <Carousel indicators={false}>
        {groupedPosts.map((group, index) => (
          <Carousel.Item key={index + 1}>
            <Row key={index}>
              {group.map((post) => (
                <Col key={post._id} md={4}>
                  <Card className="latestPosts">
                    <Card.Img
                      variant="top"
                      src={post.image}
                      alt={post.title}
                      className="latestPostImg"
                    />
                    <Card.Body className="cardBody">
                      <Card.Title
                        className="latestPostsTitle"
                        onClick={() => navigate(`/specific-news/${post._id}`)}
                        style={{color: mode === "dark" ? "white" : "black"}}
                      >
                        {post.title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default React.memo(LatestPosts);
