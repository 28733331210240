import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style/adsView.css";
import { AppContext } from "../../context/modeContext";
import AdsTable from "../../components/adsTable/AdsTable";
import axios from "axios";
import { apiUrl } from "../../constants";

const AdsView = () => {
  const navigate = useNavigate();
  const { mode } = useContext(AppContext);
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");


  // get Ads
  const getAds = async () => {
    console.log("get ads");
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/companyAds/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      console.log("response: ", response);
      if(response.data){
        setAds(response.data.data.companyAds)
        setLoading(false)
      }else {
        setLoading(false)
        console.log("wrong in response");
        
      }
    } catch (error) {
      console.log("error calling api", error);
      setLoading(false)
    }
    
  }


  useEffect(() => {
    getAds()
  }, [])

  return (
    <div className="container postsView">
      <div className="createButtonContainer">
        <Link
          to={"/create-ads"}
          className="createButton"
          style={{ color: mode === "dark" ? "white" : "black" }}
        >
          اضافة اعلان
        </Link>
      </div>
      {loading ? (
        <div className="loading my-5 mx-auto text-center">
          <h3>جار التحميل</h3>
        </div>
      ) : null}
      <div className="table">
        <AdsTable
          columns={["اسم الشركة", "الصورة", "الاجراءات"]}
          values={ads}
          onClickUpdate={() => console.log("update")}
          onClickDelete={() => console.log("deleted")}
        />
      </div>
    </div>
  );
};

export default AdsView;
