import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const TranslateButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default language

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleTranslate = () => {
    const currentURL = window.location.href;
    const translateURL = `https://translate.google.com/translate?sl=auto&tl=${selectedLanguage}&u=${encodeURIComponent(currentURL)}`;
    window.open(translateURL, '_blank');
    handleClose();
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Translate
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Language to translate into</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            className="form-control"
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.target.value)
              
            }}
          >
            <option value="en">English</option>
            <option value="de">German</option>
            <option value="fr">French</option>
            <option value="es">Spanish</option>
            <option value="nl">Dutch</option>
            <option value="ar">Arabic</option>
            {/* Add more languages as needed */}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleTranslate}>
            Translate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TranslateButton;
