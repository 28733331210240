import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./style/dashboard.css";
import { AppContext } from "../../context/modeContext";

const AdminDashboard = () => {
  const { mode } = useContext(AppContext);
  return (
    <div className="container dashboard">
      <div className="row">
        <div className="col-sm-4 part">
          <Link
            to={"/posts-dashboard"}
            className="partButton"
            style={{ color: mode === "dark" ? "white" : "black" }}
          >
            صفحات الخبر
          </Link>
        </div>
        <div className="col-sm-4 part">
          <Link
            to={"/ads-view"}
            className="partButton"
            style={{ color: mode === "dark" ? "white" : "black" }}
          >
            صفحات الاعلان
          </Link>
        </div>
        <div className="col-sm-4 part">
          <Link
            to={"/employees-view"}
            className="partButton"
            style={{ color: mode === "dark" ? "white" : "black" }}
          >
            صفحات الموظفين
          </Link>
        </div>
        <div className="col-sm-4 part">
          <Link
            to={"/talks-view"}
            className="partButton"
            style={{ color: mode === "dark" ? "white" : "black" }}
          >
            صفحات كلمة رواد التنمية
          </Link>
        </div>
        <div className="col-sm-4 part">
          <Link
            to={"/dis-ads-view"}
            className="partButton"
            style={{ color: mode === "dark" ? "white" : "black" }}
          >
              صفحات اعلانات ذوي الاعاقة
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
