import "./App.css";
import NavBar from "./components/navbar/NavBar";
import { useContext } from "react";
import { AppContext } from "./context/modeContext";
import Footer from "./components/footer/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import News from "./pages/news/News";
import Login from "./pages/login/Login";
import SpecificNew from "./pages/specificNew/SpecificNew";
import CreatePost from "./pages/createPost/CreatePost";
import CreateAuthor from "./pages/createAuthor/CreateAuthor";
import CreateTalks from "./pages/createTalk/CreateTalks";
import CreateAds from "./pages/createAds/CreateAds";
import Talks from "./pages/pioneerTalks/Talks";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import PostsView from "./pages/adminPostsView/PostsView";
import UpdatePost from "./pages/updatePost/UpdatePost";
import AdsView from "./pages/adminAdsView/AdsView";
import UpdateAds from "./pages/updateAds/UpdateAds";
import EmployeesView from "./pages/employeesView/EmployeesView";
import UpdateEmp from "./pages/updateEmployee/UpdateEmp";
import TalksView from "./pages/talksView/TalksView";
import UpdateTalks from "./pages/updateTalks/UpdateTalks";
import ContactUs from "./pages/contactUs/ContactUs";
import AdsDisPage from "./pages/adsForDisPeople/adsDisPage/AdsDisPage";
import SpecialAdsDis from "./pages/adsForDisPeople/specialAdsDis/SpcialAdsDis";
import AdsDisPeopleView from "./pages/adsForDisPeople/adsForDisPeopleView/AdsDisPeopleView";
import CreateAdsDis from "./pages/adsForDisPeople/createAdForDisPeople/CreateAdsDis";

function App() {
  const { mode } = useContext(AppContext);
  return (
    <div
      className="App"
      style={{ backgroundColor: mode === "light" ? "#f5f5f5" : "#191a1f" }}
    >
      <div className="content">
        <NavBar />
        <Routes>
          <Route path="/admin-dashboard" element={<AdminDashboard/>}/>

          {/* posts crud */}
          <Route path="/posts-dashboard" element={<PostsView/>}/>
          <Route path="/create-post" element={<CreatePost/>}/>
          <Route path="/update-post/:id" element={<UpdatePost/>}/>
          
          {/* employees crud */}
          <Route path="/create-author" element={<CreateAuthor/>}/>
          <Route path="/employees-view" element={<EmployeesView/>}/>
          <Route path="/update-employee/:id" element={<UpdateEmp/>}/>

          {/* talks crud */}
          <Route path="/create-talks" element={<CreateTalks/>}/>
          <Route path="/talks" element={<Talks/>}/>
          <Route path="/talks-view" element={<TalksView/>}/> 
          <Route path="/update-talks/:id" element={<UpdateTalks/>}/> 

          {/* ads crud */}
          <Route path="/create-ads" element={<CreateAds/>}/>
          <Route path="/ads-view" element={<AdsView/>}/>
          <Route path="/update-ads" element={<UpdateAds/>}/>

          {/* visitor pages */}
          <Route path="/" element={<Home/>}/> 
          <Route path="/contact-us" element={<ContactUs/>}/> 
          <Route path="/news/:category" element={<News/>}/>
          <Route path="/specific-news/:id" element={<SpecificNew/>}/>
          

          {/* login */}
          <Route path="/login" element={<Login/>}/>

          {/* ads for disabled people */}
          <Route path="/disabled-people-ads" element={<AdsDisPage/>}/>
          <Route path="/disabled-ads/:adId" element={<SpecialAdsDis/>}/>
          <Route path="/dis-ads-view" element={<AdsDisPeopleView/>}/>
          <Route path="/create-dis-ads" element={<CreateAdsDis/>}/>
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
