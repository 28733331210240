import React, { useContext, useState } from "react";
import Input from "../../../components/input/Input";
import { AppContext } from "../../../context/modeContext";
import "./style/createAdsDisPeople.css";
import axios from "axios";
import { apiUrl } from "../../../constants";
import { useNavigate } from "react-router-dom";

const CreateAdsDis = () => {
  const [productName, setProductName] = useState("");
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [phone, setPhone] = useState("");
  const [beneficiaryGroup, setBeneficiaryGroup] = useState("");
  const [adsType, setAdsType] = useState("free");
  const [loading, setLoading] = useState(false);
  const { mode } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleUpload = async (file) => {
    console.log("handle file upload");
    console.log("image file: ", file);

    // Create FormData and append the file
    const formData = new FormData();
    formData.append("file", file); // 'file' should match the key expected by the backend

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/upload/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file upload
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("img response", response.data);
      setImage(response.data.file.path); // Update this based on your backend's response
    } catch (error) {
      console.error("error uploading image", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file); // Set the selected file
    handleUpload(file); // Upload the file immediately
  };

  const createAds = async () => {
    console.log("create dis ads");
    setLoading(true);
    if (productName.length === 0) {
      setLoading(false);
      setError("يجب ادخال اسم المنتج");
    } else if (image.length === 0) {
      setLoading(false);
      setError("يجب ادخال صورة المنتج");
    } else if (country.length === 0) {
      setLoading(false);
      setError("يجب ادخال الدولة");
    } else if (description.length === 0) {
      setLoading(false);
      setError("يجب ادخال تفاصيل المنتج");
    } else if (ageGroup.length === 0) {
      setLoading(false);
      setError("يجب ادخال الفئة العمرية");
    } else if (beneficiaryGroup.length === 0) {
      setLoading(false);
      setError("يجب ادخال الفئة المستفيدة");
    } else if (phone.length === 0) {
      setLoading(false);
      setError("يجب ادخال التليفون");
    } else {
      setError("")
      try {
        const response = await axios.post(
          `${apiUrl}/api/v1/disabilityAds/create-disabilityAd`,
          {
            productName,
            image,
            beneficiaryGroup,
            ageGroup,
            country,
            phone,
            productOfferType: adsType,
            description,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("create response", response);
        if (response.data) {
          setLoading(false);
          alert("تمت الاضافة بنجاح");
          navigate("/dis-ads-view")
        } else {
          setLoading(false);
          alert("هناك خطا تاكد من ادخال البيانات");
        }
      } catch (error) {
        setLoading(false);
        console.error("error in fetching create ads", error);
        alert("هناك خطا حاول في وقت اخر");
      }
    }
  };

  return (
    <div className="createPost container">
      <h1 style={{ color: mode === "dark" ? "white" : "black" }}>
        انشاء اعلان لذوي الاعاقة
      </h1>
      <div className="formPost">
        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              اسم المنتج
            </label>
            <Input
              type={"text"}
              value={productName}
              onchange={(e) => setProductName(e.target.value)}
              placeholder={"اسم المنتج"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              رقم الهاتف
            </label>
            <Input
              type={"text"}
              value={phone}
              onchange={(e) => setPhone(e.target.value)}
              placeholder={"رقم الهاتف"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الدولة
            </label>
            <Input
              type={"text"}
              value={country}
              onchange={(e) => setCountry(e.target.value)}
              placeholder={"الدولة"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الفئة العمرية
            </label>
            <Input
              type={"text"}
              value={ageGroup}
              onchange={(e) => setAgeGroup(e.target.value)}
              placeholder={"الفئة العمرية"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              تفاصيل المنتج
            </label>
            <Input
              type={"text"}
              value={description}
              onchange={(e) => setDescription(e.target.value)}
              placeholder={"معلومات عن المنتج"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label
              style={{ color: mode === "dark" ? "white" : "black" }}
              className="d-block"
            >
              نوع الاعلان
            </label>
            <select
              className="selectt"
              name="category"
              id="category"
              value={adsType}
              onChange={(e) => setAdsType(e.target.value)}
              style={{
                backgroundColor: "rgba(33, 99, 232, 0.2)",
                color: mode === "dark" ? "white" : "black",
              }}
            >
              <option value={"free"}>مجاني</option>
              <option value={"sale"}>للبيع</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              الفئة المستفيدة
            </label>
            <Input
              type={"text"}
              value={beneficiaryGroup}
              onchange={(e) => setBeneficiaryGroup(e.target.value)}
              placeholder={"الفئة المستفيدة"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>

          <div className="col-sm-6 input-label">
            <label style={{ color: mode === "dark" ? "white" : "black" }}>
              صورة الاعلان
            </label>
            <Input
              type={"file"}
              onchange={handleFileChange}
              placeholder={"صورة الاعلان"}
              bgcolor={mode === "dark" ? "#f5f5f5" : "#191a1f"}
            />
          </div>
        </div>

      </div>

      {error.length > 0 && <h4 className="text-danger text-center my-3 mx-auto">{error}</h4>}

      <div className="createBtn">
        <button onClick={createAds}>
          {loading ? "جاري الاضافة" : "اضافة"}
        </button>
      </div>
    </div>
  );
};

export default CreateAdsDis;
